<template>
  <v-card>
    <v-toolbar color="grey lighten-2">
      <v-toolbar-title class="text-h5">Payment Details</v-toolbar-title>
      <v-spacer> </v-spacer> <v-btn @click="print">Print</v-btn>
    </v-toolbar>
    <div id="paymentDetailsPrint">
      <v-divider class="mb-4"> </v-divider>
      <v-card-text>Transaction ID: {{ payment.transaction_id }}</v-card-text>
      <v-card-text
        >Paid On:
        {{ new Date(payment.created_at).toLocaleString() }}</v-card-text
      ><v-card-text>Total Paid: {{ payment.amount }}</v-card-text>
      <v-divider> </v-divider>
      <v-data-table
        class="ma-4 table"
        :headers="headers"
        :items="payment.room_user_fee"
        :hide-default-footer="true"
        disable-sort
      >
      </v-data-table>
    </div>

    <v-divider></v-divider>
  </v-card>
</template>

<script>
export default {
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Month",
          value: "month",
        },
        {
          text: "Year",
          value: "year",
        },
        {
          text: "Amount",
          value: "amount",
        },
      ],
    };
  },
  methods: {
    print() {
      let printContents, popupWin;
      printContents = document.getElementById("paymentDetailsPrint").innerHTML;
      popupWin = window.open(
        "",
        "_blank",
        "top=0,left=0,height=100%,width=auto"
      );

      popupWin.document.write(`
          <html>
            <head>
              <title>${this.payment.id}</title>
              <style>
                //........Customized style.......
                @media print {
                  @page {
                    size: A4;
                    margin: 0;
                  }
                  body {
                    margin: 1.6cm;
                  }
                  .table{
                    width: 100%;

                  }
                  table, th, td {
                    border: 1px solid black;
                    border-collapse: collapse;
                  }

                }
              </style>
            </head>
        <body onload="window.print()">${printContents}</body>
      
        </html>`);
      popupWin.document.close();
    },
  },
};
</script>

<style>
@media print {
  body * {
    visibility: hidden;
    font-size: 20px !important;
  }

  #print,
  #print * {
    visibility: visible;
    border-bottom: none;
  }

  #title {
    visibility: hidden;
  }

  #print {
    padding: 20px;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>