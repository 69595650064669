<template>
  <v-card>
    <v-card-title>Payment History</v-card-title>
    <v-data-table
      :headers="headers"
      :items="$store.state.payment.payment_history"
    >
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template #[`item.action`]="{ item }">
        <v-dialog v-model="item.dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark v-bind="attrs" v-on="on"> Details </v-btn>
          </template>
          <PaymentHistoryDetails :payment="item"></PaymentHistoryDetails>
        </v-dialog>
      </template>
    </v-data-table>

    <PaymentHistoryDetails
      v-if="showDetailsDialog"
      :payment="selectedPayment"
      @close="showDetailsDialog = false"
    />
  </v-card>
</template>

<script>
import PaymentHistoryDetails from "./PaymentHistoryDetails.vue";
export default {
  data() {
    return {
      showDetailsDialog: false,
      selectedPayment: null,
      headers: [
        { text: "Date", value: "created_at" },
        { text: "Amount", value: "amount" },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("payment/fetchPaymentHistory");
  },
  components: { PaymentHistoryDetails },
};
</script>

<style></style>
